import { Injector, ElementRef } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
    LocalizationService,
    PermissionCheckerService,
    FeatureCheckerService,
    NotifyService,
    SettingService,
    MessageService,
    AbpMultiTenancyService
} from 'abp-ng2-module';

import { AppSessionService } from '@shared/session/app-session.service';
import { PermissionNames } from './PermissionNames';
import { forEach } from 'lodash-es';

export abstract class AppComponentBase {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
    }

    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    public hasMaintenancePermissions(): boolean {
        for (var i = 0; i < PermissionNames.MaintenancePermissions.length; i++) {
            if (this.permission.isGranted(PermissionNames.MaintenancePermissions[i])) {
                return true;
            }
        }
        return false;
    }

    public isMaintenanceAgentOnly(): boolean {
        var agentPermissionsGranted: boolean = true;

        var excludedProperties = new Set<string>(Object.getOwnPropertyNames(class { }));

        var agentPermissionsHash = new Set<string>(PermissionNames.AgentMaintenancePermissions);

        var properties = Object.getOwnPropertyNames(PermissionNames);
        for (var i = 0; i < properties.length; i++) {
            var currentValue = PermissionNames[properties[i]];
            if (!excludedProperties.has(currentValue) && (typeof currentValue === 'string' || currentValue instanceof String)) {
                var permission = currentValue as string;
                var permissionGranted = this.permission.isGranted(permission);
                if (agentPermissionsHash.has(permission)) {
                    agentPermissionsGranted = agentPermissionsGranted && permissionGranted;
                } else if (permissionGranted) {
                    return false;
                }
            }
        }

        console.log(agentPermissionsGranted);
        return agentPermissionsGranted;
    }

    public isRepairAgentOnly(): boolean {
        var agentPermissionsGranted: boolean = true;

        var excludedProperties = new Set<string>(Object.getOwnPropertyNames(class { }));

        var agentPermissionsHash = new Set<string>(PermissionNames.AgentRepairPermissions);

        var properties = Object.getOwnPropertyNames(PermissionNames);
        for (var i = 0; i < properties.length; i++) {
            var currentValue = PermissionNames[properties[i]];
            if (!excludedProperties.has(currentValue) && (typeof currentValue === 'string' || currentValue instanceof String)) {
                var permission = currentValue as string;
                var permissionGranted = this.permission.isGranted(permission);
                if (agentPermissionsHash.has(permission)) {
                    agentPermissionsGranted = agentPermissionsGranted && permissionGranted;
                } else if (permissionGranted) {
                    return false;
                }
            }
        }

        console.log(agentPermissionsGranted);
        return agentPermissionsGranted;
    }

    public hasInventoryPermissions(): boolean {
        for (var i = 0; i < PermissionNames.InventoryPermissions.length; i++) {
            if (this.permission.isGranted(PermissionNames.InventoryPermissions[i])) {
                return true;
            }
        }
        return false;
    }

    public hasMaintenanceAgentRights(): boolean {
        if (this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_MaintenancePlanning_Agent) &&
            (!this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_MaintenancePlanning_Company))) {
            return true;
        }
        return false;
    }

    public hasMaintenanceCompanyRights(): boolean {
        if (this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_MaintenancePlanning_Company)) {
            return true;
        }
        return false;
    }

    public hasRepairAgentRights(): boolean {
        if (this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_RepairPlanning_Agent) &&
            (!this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_RepairPlanning_Company))) {
            return true;
        }
        return false;
    }

    public hasRepairCompanyRights(): boolean {
        if (this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_RepairPlanning_Company)) {
            return true;
        }
        return false;
    }

    public hasMaintenanceDoneAgentRights(): boolean {
        if (this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_MaintenanceDone_Agent) &&
            (!this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_MaintenanceDone_Company))) {
            return true;
        }
    }

    public hasRepairDoneAgentRights(): boolean {
        if (this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_RepairDone_Agent) &&
            (!this.permission.isGranted(PermissionNames.Pages_Scanning_Reports_RepairDone_Company))) {
            return true;
        }
    }
}
