import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { FixedHeightDirective } from './directives/fixed-height.directive';
import { LoadingDialogComponent } from '@shared/loading/loading-dialog.component';
import { PictureDialogComponent } from '@shared/picture-dialog/picture-dialog.component';

import { ImportColumnDto } from '@shared/import/dto/import-column.dto';
import { LoadingStateDto } from '@shared/import/dto/loading-state.dto';
import { ImportDisplayColumnDto } from '@shared/import/dto/import-display-column.dto';
import { Drag } from '@shared/OpenLayers/drag.internal';
import { ScriptService } from '@shared/script-service/script-service';
import { AppRendererComponent, RenderItemDirective } from '@shared/renderer/app-renderer.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        PerfectScrollbarModule
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        FixedHeightDirective,
        LoadingDialogComponent,
        PictureDialogComponent,
        AppRendererComponent,
        RenderItemDirective
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        FixedHeightDirective,
        AppRendererComponent,
        RenderItemDirective
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                ScriptService
            ]
        };
    }
}
