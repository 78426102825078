import {
    Component,
    Injector,
    OnInit,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/app-component-base';
import * as $ from "jquery";

@Component({
    templateUrl: 'loading-dialog.component.html'
})
export class LoadingDialogComponent extends AppComponentBase
    implements OnInit {

    currentCount: number;

    maxValue: number;

    isFinishedCallback: () => boolean;

    setRefreshCallback: (refreshCountCallback: (count: number) => void) => void;

    text: string;

    constructor(
        injector: Injector,
        public bsModalRef: BsModalRef,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.setRefreshCallback) {
            this.setRefreshCallback((count) => {
                this.refreshLoadingCount(count);
            });
        }
    }

    private sizeChecked: boolean = false;

    checkSize(): void {
        if (this.sizeChecked) {
            return
        }
        var modalDialog = $('.loadingModal.modal-dialog');
        var height = modalDialog.height();
        if (height) {
            this.sizeChecked = true;
            modalDialog.css('margin-top', (-height / 2) + 'px');

            if (this.isFinishedCallback && this.isFinishedCallback()) {
                this.bsModalRef.hide();
            }
        }
    }

    private refreshLoadingCount(count: number): void {
        if (count > this.maxValue) {
            this.currentCount = this.maxValue;
        } else {
            this.currentCount = count;
        }
        this._changeDetectorRef.detectChanges();
    }

}
