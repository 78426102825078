import { Component, ComponentFactory, ComponentRef, Directive, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";

@Directive({
    selector: '[renderItem]',
})
export class RenderItemDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
    selector: 'app-renderer',
    template: '<ng-template renderItem></ng-template>'
})
export class AppRendererComponent {

    constructor(
        private el: ElementRef<HTMLElement>
    ) {
    }

    @ViewChild(RenderItemDirective, { static: true })
    renderItem!: RenderItemDirective;

    public compile(factory: ComponentFactory<any>, setValues: (componentRef: ComponentRef<any>) => void): Promise<HTMLElement> {
        this.renderItem.viewContainerRef.clear();
        return new Promise(resolver => {
            var componentRef = this.renderItem.viewContainerRef.createComponent(factory);
            if (setValues) {
                setValues(componentRef);
            }
            setTimeout(() => {
                resolver(this.el.nativeElement);
            });
        });
    }

}
