import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import * as $ from "jquery";

@Directive({
    selector: '[fixed-height]',
})
export class FixedHeightDirective implements OnDestroy {

    private _inverval: any;

    constructor(private _element: ElementRef) {
        var element = _element.nativeElement;
        var setHeightEventListener = () => {
            var offset = element.getBoundingClientRect();
            var height = window.innerHeight - Math.ceil(offset.top) - 3;

            var currentParent = element.parentElement;
            while (currentParent) {
                var parentStyle = window.getComputedStyle(currentParent);
                var parentMarginBottom = parseInt(parentStyle.marginBottom);
                if (parentMarginBottom) {
                    height -= parentMarginBottom;
                }
                var parentPaddingBottom = parseInt(parentStyle.paddingBottom);
                if (parentPaddingBottom) {
                    height -= parentPaddingBottom;
                }
                currentParent = currentParent.parentElement;
            }
            var currentMinHeight = 150;
            if (this.minHeight) {
                currentMinHeight = this.minHeight;
            }

            if (height > currentMinHeight) {
                element.style.height = height + 'px';
            } else {
                element.style.height = currentMinHeight + 'px';
            }
        }
        this._inverval = $(element).onPositionChanged(setHeightEventListener, 0);
        setHeightEventListener();
    }

    @Input()
    minHeight: number;

    ngOnDestroy(): void {
        if (this._inverval) {
            clearInterval(this._inverval);
        }
    }
}


declare global {
    interface JQuery {
        onPositionChanged(trigger: any, millis: number): JQuery<any> | NodeJS.Timeout;
    }
}

$.fn.onPositionChanged = function (trigger, millis) {
    if (millis == null) millis = 500;
    var element = $(this[0]);
    if (element.length < 1) return element;

    var lastPos = element.position();
    var lastOff = element.offset();
    var lastWindowHeight = window.innerHeight;
    var lastElementHeight = element.height();
    var interval = setInterval(function () {
        if (element == null || element.length < 1) return element;
        if (lastPos == null) lastPos = element.position();
        if (lastOff == null) lastOff = element.offset();
        if (lastWindowHeight == null) lastWindowHeight = window.innerHeight;
        if (lastElementHeight == null) lastElementHeight = element.height();
        var newPos = element.position();
        var newOff = element.offset();
        var newElementHeight = 0;
        var subElements = element.find(' > *');
        if (subElements.length) {
            for (var index = 0; index < subElements.length; index++) {
                newElementHeight += $(subElements[index]).height();
            }
        } else {
            newElementHeight = element.height();
        }
        if (lastPos.top != newPos.top || lastPos.left != newPos.left) {
            $(this).trigger('onPositionChanged', { lastPos: lastPos, newPos: newPos });
            if (typeof (trigger) == "function") trigger(lastPos, newPos);
            lastPos = element.position();
            return;
        }
        if (lastOff.top != newOff.top || lastOff.left != newOff.left) {
            $(this).trigger('onOffsetChanged', { lastOff: lastOff, newOff: newOff });
            if (typeof (trigger) == "function") trigger(lastOff, newOff);
            lastOff = element.offset();
            return;
        }
        if (lastWindowHeight != window.innerHeight) {
            $(this).trigger('onWindowHeightChanged', { lastWindowHeight: lastWindowHeight, newWindowHeight: window.innerHeight });
            if (typeof (trigger) == "function") trigger(lastOff, newOff);
            lastWindowHeight = window.innerHeight;
        }
        if (lastElementHeight != newElementHeight) {
            $(this).trigger('onElementHeightChanged', { lastElementHeight: lastElementHeight, newElementHeight: newElementHeight });
            if (typeof (trigger) == "function") trigger(lastOff, newOff);
            lastElementHeight = newElementHeight;
        }
    }, millis);

    return interval;
}
