<form class="form-horizontal"
      autocomplete="off">
    <div class="modal-body">
        <div class="text-center" style="height: 200px; position: relative;">
            <div class="freeze-ui" style="position: absolute;">
            </div>
        </div>
        <div *ngIf="maxValue" class="text-center">
            {{currentCount}} / {{maxValue}}
        </div>
        <div class="text-center">
            <h4>{{text}}</h4>
        </div>
        <div class="text-center" style="padding-top: 5px; padding-bottom: 20px;">
            <span style="font-size: 15px;">{{'LoadingModal_PleaseWait' | localize}}</span>
        </div>
    </div>
    {{checkSize()}}
</form>
