import {
    Component,
    Injector
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
    templateUrl: 'picture-dialog.component.html'
})
export class PictureDialogComponent extends AppComponentBase {

    perfectScrollbarConfig: PerfectScrollbarConfigInterface = {
        minScrollbarLength: 50,
        suppressScrollX: true
    };

    remoteServiceUrl = AppConsts.remoteServiceBaseUrl;
    tenantId = this.appSession.tenantId;

    constructor(
        injector: Injector,
        public bsModalRef: BsModalRef
    ) {
        super(injector);
    }

    id: string = null;

    close(): void {
        this.bsModalRef.hide();
    }
}
