import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.CostCenterServiceProxy,
        ApiServiceProxies.MaintenanceOperationServiceProxy,
        ApiServiceProxies.AssetServiceProxy,
        ApiServiceProxies.FileServiceProxy,
        ApiServiceProxies.PhysicalItemServiceProxy,
        ApiServiceProxies.ScanningReportServiceProxy,
        ApiServiceProxies.DashboardServiceProxy,
        ApiServiceProxies.SettingServiceProxy,
        ApiServiceProxies.PhysicalLocationServiceProxy,
        ApiServiceProxies.DocumentServiceProxy,
        ApiServiceProxies.DocumentHistoryServiceProxy,
        ApiServiceProxies.RevisionServiceProxy,
        ApiServiceProxies.RepairServiceProxy,
        ApiServiceProxies.AlertServiceProxy,
        ApiServiceProxies.OnPremiseServiceProxy,
        ApiServiceProxies.BeneficiaryServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
