export class PermissionNames {

    static readonly Pages_Tenants: string = 'Pages.Tenants';

    static readonly Pages_Users: string = 'Pages.Users';

    static readonly Pages_Users_Activation: string = 'Pages.Users.Activation';

    static readonly Pages_Roles: string = 'Pages.Roles';

    static readonly Pages_Alerts: string = 'Pages.Alerts';

    static readonly Pages_Scanning: string = 'Pages.Scanning';

    static readonly Pages_Maintenance: string = 'Pages.Maintenance';

    static readonly Pages_Repair: string = 'Pages.Repair';

    static readonly Pages_Config_Assets: string = 'Pages.Config.Assets';

    static readonly Pages_Config_CostCenters: string = 'Pages.Config.CostCenters';

    static readonly Pages_Config_MaintenanceOperations: string = 'Pages.Config.MaintenanceOperations';

    static readonly Pages_Scanning_Reports_Identified: string = 'Pages.Scanning.Reports.Identified';

    static readonly Pages_Scanning_Reports_NotFound: string = 'Pages.Scanning.Reports.NotFound';

    static readonly Pages_Scanning_Reports_Stocks: string = 'Pages.Scanning.Reports.Stocks';

    static readonly Pages_Scanning_Reports_MaintenancePlanning_Agent: string = 'Pages.Scanning.Reports.MaintenancePlanning.Agent';

    static readonly Pages_Scanning_Reports_MaintenancePlanning_Company: string = 'Pages.Scanning.Reports.MaintenancePlanning.Company';

    static readonly Pages_Scanning_Reports_RepairPlanning_Agent: string = 'Pages.Scanning.Reports.RepairPlanning.Agent';

    static readonly Pages_Scanning_Reports_RepairPlanning_Company: string = 'Pages.Scanning.Reports.RepairPlanning.Company';

    static readonly Pages_Scanning_Reports_RepairDone_Agent: string = 'Pages.Scanning.Reports.RepairDone.Agent';

    static readonly Pages_Scanning_Reports_RepairDone_Company: string = 'Pages.Scanning.Reports.RepairDone.Company';

    static readonly Pages_Scanning_Reports_MaintenanceDone_Agent: string = 'Pages.Scanning.Reports.MaintenanceDone.Agent';

    static readonly Pages_Scanning_Reports_MaintenanceDone_Company: string = 'Pages.Scanning.Reports.MaintenanceDone.Company';

    static readonly Pages_Config_Settings: string = 'Pages.Config.Settings';

    static readonly Pages_Config_PhysicalLocations: string = 'Pages.Config.PhysicalLocations';

    static readonly Pages_Config_PhysicalLocations_Edit: string = 'Pages.Config.PhysicalLocations.Edit';

    static readonly Pages_Documents_InventoryDecision: string = 'Pages.Documents.InventoryDecision';

    static readonly Pages_Documents_ManagerialStatements: string = 'Pages.Documents.ManagerialStatements';

    static readonly Pages_Documents_InventoryStatement: string = 'Pages.Documents.InventoryStatement';

    static readonly Pages_Documents_Cassation: string = 'Pages.Documents.Cassation';

    static readonly Pages_Documents_History: string = 'Pages.Documents.History';

    static readonly Pages_Association_Unidentified: string = 'Pages.Scanning.Reports.Unidentified';

    static readonly Pages_Association_Auto: string = 'Pages.Association.Auto';

    static readonly Pages_Dashboard_Inventory: string = 'Pages.Dashboard.Inventory';

    static readonly Pages_Dashboard_Maintenance: string = 'Pages.Dashboard.Maintenance';

    static readonly Pages_Beneficiaries: string = 'Pages.Beneficiaries';

    static readonly Pages_Reports_Beneficiary_Assets: string = 'Pages.Reports.Beneficiary.Assets';

    static readonly InventoryPermissions: string[] = [
        PermissionNames.Pages_Scanning,
        PermissionNames.Pages_Scanning_Reports_Identified,
        PermissionNames.Pages_Scanning_Reports_NotFound,
        PermissionNames.Pages_Scanning_Reports_Stocks,
        PermissionNames.Pages_Association_Unidentified,
        PermissionNames.Pages_Association_Auto,
        PermissionNames.Pages_Dashboard_Inventory
    ]

    static readonly MaintenancePermissions: string[] = [
        PermissionNames.Pages_Maintenance,
        PermissionNames.Pages_Scanning_Reports_MaintenancePlanning_Agent,
        PermissionNames.Pages_Scanning_Reports_MaintenancePlanning_Company,
        PermissionNames.Pages_Scanning_Reports_MaintenanceDone_Agent,
        PermissionNames.Pages_Scanning_Reports_MaintenanceDone_Company,
        PermissionNames.Pages_Dashboard_Maintenance
    ]

    static readonly AgentMaintenancePermissions: string[] = [
        PermissionNames.Pages_Maintenance,
        PermissionNames.Pages_Scanning_Reports_MaintenancePlanning_Agent
    ]

    static readonly RepairPermissions: string[] = [
        PermissionNames.Pages_Repair,
        PermissionNames.Pages_Scanning_Reports_RepairPlanning_Agent,
        PermissionNames.Pages_Scanning_Reports_RepairPlanning_Company,
        PermissionNames.Pages_Scanning_Reports_RepairDone_Agent,
        PermissionNames.Pages_Scanning_Reports_RepairDone_Company
    ]

    static readonly AgentRepairPermissions: string[] = [
        PermissionNames.Pages_Repair,
        PermissionNames.Pages_Scanning_Reports_RepairPlanning_Agent
    ]
}
