<form class="form-horizontal"
      autocomplete="off">
    <div class="modal-header">
        <div class="modal-title">
            {{ "Image" | localize }}
        </div>
        <button type="button" class="close" (click)="close()"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar [config]="perfectScrollbarConfig">
            <div class="form-group" *ngIf="id">
                <div class="col-md-12 asset-details-image-wrapper" style="height: calc(100vh - 160px)">
                    <img src="{{remoteServiceUrl}}/api/File/Get?fileId={{id}}&t={{tenantId}}" />
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</form>
