import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { LayoutConfig } from './layout-config';

@Injectable()
export class LayoutStoreService {

    private readonly initialLayoutConfig: LayoutConfig = {
        sidebarExpanded: false
    };

    public readonly config$: Observable<LayoutConfig>;

    private configSource: BehaviorSubject<LayoutConfig>;


    // reset Scanning
    private resetScanningSource: BehaviorSubject<any>;
    private readonly resetScanningObservable: Observable<any>;

    constructor() {
        this.configSource = new BehaviorSubject(this.initialLayoutConfig);
        this.config$ = this.configSource.asObservable();

        this.resetScanningSource = new BehaviorSubject(null);
        this.resetScanningObservable = this.resetScanningSource.asObservable();
    }

    get sidebarExpanded(): Observable<boolean> {
        return this.config$.pipe(
            pluck('sidebarExpanded'),
            distinctUntilChanged()
        ) as Observable<boolean>;
    }

    public setSidebarExpanded(value: boolean): void {
        this.configSource.next(
            Object.assign(this.configSource.value, { sidebarExpanded: value })
        );
    }

    get resetScanning(): Observable<any> {
        return this.resetScanningObservable;
    }

    public notifyResetScanning(): void {
        this.resetScanningSource.next(null);
    }
}
